import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
      
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-heritage/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-heritage/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestra-historia/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/a_nossa_historia/">PT</a>
                      </li>
                      <li>
                        <a href="/it/our-heritage/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-heritage/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="heritage-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/our_heritage_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/our_heritage_960x405_topSmalla.jpg"
                  />
                </div>
              </div>
            </section>
            
            <div className="page-heritage__intro">
            <h1>NACE UN ESPECIALISTA EN PROTECCIÓN SOLAR</h1>
              <p> </p>
              <p>
                Durante más de 70 años, PIZ BUIN<sup>®</sup> ha proporcionado a
                los amantes del sol de todo el mundo la protección que
                necesitan. De hecho, PIZ BUIN<sup>®</sup> fue uno de los
                primeros en hacer que el bronceado fuera más seguro gracias a la
                aplicación del sistema del Factor de Protección Solar (FPS) en
                los años sesenta.
              </p>
              <p>
                Todo empezó en 1938 cuando un estudiante de química, Franz
                Greiter, sufrió una insolación mientras practicaba alpinismo, en
                su ascenso a la cumbre PIZ BUIN<sup>®</sup>. Esto le inspiró a
                desarrollar uno de los primeros productos de protección solar
                del mundo en 1946, el PIZ BUIN<sup>®</sup> Glacier Cream. En
                colaboración con su esposa, Marga, una experta esteticista, creó
                una marca especializada de cuidado solar para desarrollar
                productos de protección solar vanguardistas, y así proporcionar
                a todo aquel que quisiera disfrutar de la vida a pleno sol un
                cuidado lujoso para la piel.{" "}
              </p>
            </div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/h-2019-hydro-infusion-sun-gel-cream-30spf-150ml.jpg" />
                  </figure>
                  <h2 className="year_header">2019</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Hydro Infusion</strong>
                    <br />
                    Lanzamiento del NUEVO SOLAR HYDRO INFUSION DE PIZ BUIN ®
                    <br />
                    Refréscate al instante con la gama PIZ BUIN HYDRO INFUSION®
                    de rápida<br /> absorción.
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/h-2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" />
                  </figure>
                  <h2 className="year_header">2018</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Tan &amp; Protect</strong>
                    <br />
                    Lanzamiento del NUEVO SPRAY TAN &amp; PROTECT DE PIZ BUIN ®
                    <br />
                    El Aceite en Spray Tan&amp;Protect, acelera el proceso de
                    bronceado natural de la<br /> piel* de forma rápida a la vez que
                    le protege eficazmente contra los rayos UVA<br /> y UVB. Aceite no
                    graso y de rápida absorción.
                    <br />
                    *Pruebas in vitro
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/InstantGlow_Heritage.jpg" />
                  </figure>
                  <h2 className="year_header">2017</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Instant Glow</strong>
                    <br />
                    Lanzamiento del NUEVO PIZ BUIN Instant Glow.
                    <br />
                    Loción solar para un bronceado luminoso y radiante.
                    <br />
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/Protect_and_Cool_Frozen_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Protect &amp; Cool</strong>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lanzamiento del NUEVO PIZ BUIN PROTECT &amp; COOL
                    <sup>®</sup> <br />
                    Mousse solar refrescante con agradable sensación de frescor
                    helado<strong>.</strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/70_Years_Celebration_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Piz Buin viaja a Costa Rica con sus embajadoras de marca{" "}
                    <br />
                    para que puedan compartir sus mejores experiencias bajo el
                    sol.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/Sun_Finder_640px_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Sun Finder</strong>
                  </p>
                  <p style={{
                                  textAlign:"center"}}>
                    ¡Piz Buin cumple 70 años de protección y belleza bajo el
                    sol! Para celebrarlo,<br /> nace Piz Buin Sun Finder, una web app
                    con la mejor selección de los destinos<br /> más cool y glamurosos
                    del mundo.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/2015-KV-Instant-Glow-Vertical.jpg" />
                  </figure>
                  <h2 className="year_header">2015</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Presentación del NUEVO PIZ BUIN INSTANT GLOW<sup>®</sup>{" "}
                    Spray iluminador.
                    <br />
                    Protección y Belleza al instante y durante todo el verano.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2014b.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> lanza la nueva campaña 2014 WET SKIN
                    Sun Spray Transparente<br /> verano en Tailandia.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2014.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    La introducción de PIZ BUIN<sup>®</sup> ULTRA LIGHT Dry
                    Touch Fluidos.
                    <br /> La protección efectiva que se seca al instante y se
                    siente sin peso.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2013.jpg" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Presentación de PIZ BUIN WET SKIN<sup>®</sup> uno de los
                    primeros
                    <br />
                    protectores solares que se puede aplicar directamente sobre
                    la piel mojada.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2013b.jpg" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> graba en las Seychelles la nueva
                    campaña
                    <br />
                    de verano 2013 WET SKIN Spray Solar Transparente.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2012.jpg" />
                  </figure>
                  <h2 className="year_header">2012</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lanzamiento de PIZ BUIN TAN &amp; PROTECT<sup>®</sup>.<br />
                    Para lograr un bronceado más rápido* y más hermoso... de
                    forma segura.
                    <br /> *Contiene una tecnología que realza el bronceado
                    natural <br />
                    <span className="footNote">
                      *Contains a technology that enhances natural tanning
                    </span>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2011.jpg" />
                  </figure>
                  <h2 className="year_header">2011</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>65 aniversario.</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2010.jpg" />
                  </figure>
                  <h2 className="year_header">2010</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> MOUNTAIN, ahora con complejo Cold
                    Shield y extracto de Edelweiss.
                    <br />
                    PIZ BUIN<sup>®</sup> MOUNTAIN. Protección especial* contra
                    el sol, el frío y el viento a gran altitud.
                    <br /> *Diseñado para proteger del sol, el frío y el viento
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2009.jpg" />
                  </figure>
                  <h2 className="year_header">2009</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> ALLERGY ahora con Calmanelle
                    <sup>™</sup> un Complejo Protector
                    <br />
                    único que ha demostrado contribuir a reforzar la capacidad
                    de recuperación<br /> de la piel*.
                    <br />
                    *Pruebas in vitro
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2008.jpg" />
                  </figure>
                  <h2 className="year_header">2008</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    La gama de cuidado solar PIZ BUIN<sup>®</sup> se actualiza
                    con la nueva
                    <br />y revolucionaria tecnología de protección solar
                    Helioplex<sup>™</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2007.jpg" />
                  </figure>
                  <h2 className="year_header">2007</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lanzamiento de Tan Intensifier, una gama de productos con
                    una composición
                    <br />
                    innovadora que ayuda a acelerar el bronceado natural sin
                    sacrificar la<br /> protección.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_2000.jpg" />
                  </figure>
                  <h2 className="year_header">2000</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    El bronceado profundo da paso al tono “acariciado por el
                    sol”.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1993.jpg" />
                  </figure>
                  <h2 className="year_header">1993</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    El primer PIZ BUIN<sup>®</sup> de cuidado solar con triple
                    tecnología de protección y<br /> Vitamina E.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1984.jpg" />
                  </figure>
                  <h2 className="year_header">1984</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Los anuncios ‘Diosas del Sol’ refuerzan nuestra imagen como
                    marca icónica.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1974.jpg" />
                  </figure>
                  <h2 className="year_header">1974</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Uno de los primeros en introducir productos de protección
                    solar resistentes al agua.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1962b.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>Realiza pruebas en los Alpes de Suiza-Austria.</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1962a.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Uno de los primeros en implementar el Factor de Protección
                    Solar (SPF).
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1946.jpg" />
                  </figure>
                  <h2 className="year_header">1946</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> presenta su primer producto de
                    protección solar.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/heritage_img_640_1938.jpg" />
                  </figure>
                  <h2 className="year_header">1938</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    El Dr. Franz Greiter escala la montaña PIZ BUIN<sup>®</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        </div>
    );
  }
}

export default Page;
